import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import Submit from '../components/Submit';
import Grid, { Full, Third } from '../components/Grid';
import { stripEmptyStrings } from './user';
import {
  MdPersonPin,
  MdBusiness,
  MdEmail,
  MdPhone,
  MdWbIncandescent,
  MdAttachMoney
} from 'react-icons/md';

const ContactForm = ({ action }) => (
  <Formik
    initialValues={{
      company: '',
      roleType: '',
      compensationType: '',
      email: '',
      phone: '',
      name: '',
      description: ''
    }}

    onSubmit={(variables, actions) =>
      action({ variables: stripEmptyStrings(variables) })
        .then(() => actions.resetForm())
    }

    render={() => (
      <Form>
        <Grid>
          <Third>
            <label>
              Company *
              <Field name="company" type="text" required />
              <MdBusiness />
            </label>
          </Third>
          <Third>
            <label>
              Name *
              <Field name="name" type="text" required />
              <MdPersonPin />
            </label>
          </Third>
          <Third>
            <label>
              Email *
              <Field name="email" type="email" required />
              <MdEmail />
            </label>
          </Third>
        </Grid>
        <Grid>
          <Third>
            <label>
              Phone
              <Field name="phone" type="tel" required />
              <MdPhone />
            </label>
          </Third>
          <Third>
            <label>
              Role Type *
              <Field name="roleType" component="select" required>
                <option value>-- Select --</option>
                <option value="Contract">Contract</option>
                <option value="Permanent">Permanent</option>
              </Field>
              <MdWbIncandescent />
            </label>
          </Third>
          <Third>
            <label>
              Compensation Type *
              <Field name="compensationType" component="select" required>
                <option value>-- Select --</option>
                <option value="rate">Rate</option>
                <option value="salary">Salary</option>
              </Field>
              <MdAttachMoney />
            </label>
          </Third>
        </Grid>
        <Grid>
          <Full style={{ padding: '1rem 1.5rem' }}>
            <label>
              Describe the Position
              <Field name="description" component="textarea" />
            </label>
          </Full>
        </Grid>
        <Submit text="Inquire Now" />
      </Form>
    )}
  />);

ContactForm.propTypes = {
  action: PropTypes.func
};

export default ContactForm;