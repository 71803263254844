import React from 'react';
import Helmet from 'react-helmet';
import App from './_app';
import { graphql } from 'gatsby';
import { Mutation } from 'react-apollo';
import TalentForm from '../forms/talent';
import { ContainerMedium } from '../components/Container';
import { INQUIRY } from '../queries/public';
import Alert from '../components/Alert';
import Wrap from '../components/Wrap';
import Hero from '../components/Hero';

const Contact = ({ data }) => (
  <App>
    <Helmet>
      <title>{data.page.title}</title>
      <meta name="description" content={data.page.description.description} />
    </Helmet>
    <Hero
      title={data.page.heading}
      image={data.page.image}
      subtitle={data.page.lead ? data.page.lead.lead : null}
    />
    <Wrap muted>
      <ContainerMedium>
        <Mutation mutation={INQUIRY}>
          {(query, { error, data }) => (<div>
            <TalentForm action={query} />
            {error && <Alert>Could not send the message. Please try again.</Alert>}
            {data && <Alert success>Message received. Thank you and we will be in touch soon.</Alert>}
          </div>)}
        </Mutation>
      </ContainerMedium>
    </Wrap>
  </App>
);

export default Contact;
export const query = graphql`
    query {
        page: contentfulPage(url: {eq: "talent"}){
            title
            heading
            description {
                description 
            }
            lead {
              lead
            }
            image {
                title
                fluid(maxWidth: 1200, quality: 100){
                    ...GatsbyContentfulFluid_withWebp
                }
            }
        }
      }
`;